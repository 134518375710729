<template>
  <div class="selectBox-group-left">
    <template v-if="listType == 1">
      <MessageType
        ref="MessageType"
        class="selectBox-group-item"
        :title="'问题类型'"
      ></MessageType>
      <div class="selectBox-group-item">
        <span class="m-r-8">账号名称</span>
        <el-input
          class="w190 m-r-8 m-l-8"
          v-model="zhmc"
          placeholder="请输入内容"
        ></el-input>
      </div>
    </template>
    <template v-else-if="listType == 2">
        <div class="selectBox-group-item">
            <el-select
            class="w100" 
          v-model="fansSection"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
        class="w180 m-r-6 m-l-6" 
          v-model="condition"
          clearable
          placeholder="请输入"
        ></el-input>
        </div>
      <MessageType
        ref="MessageType"
        class="selectBox-group-item"
        :title="'信息类型'"
      ></MessageType>
    </template>
    <template v-else-if="listType == 3">
        <div class="selectBox-group-item">
            <el-select
            class="w100" 
          v-model="fansSection"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
        class="w180 m-r-6 m-l-6" 
          v-model="condition"
          clearable
          placeholder="请输入"
        ></el-input>
      
        </div>
        <div class="selectBox-group-item">
          <span>错误类型</span>
        <el-select class="w180" style="margin-left:1.25rem" v-model="infoType" clearable placeholder="请选择">
          <el-option
            v-for="item in infoTypeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        </div>
    </template>
    <div class="selectBox-group-item">
        <el-date-picker
        value-format="yyyy-MM-dd HH:mm:ss" 
      v-model="time"
      type="daterange"
      range-separator="-"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker>
      <!-- <el-date-picker
       value-format="yyyy-MM-dd"
        v-model="time" type="date" placeholder="选择日期">
      </el-date-picker> -->
    </div>
  </div>
</template>

<script>
import Error from "@/api/error/index";
import MessageType from "@/components/dressing/MessageType.vue";
export default {
  components: { MessageType },
  props: {
    listType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      zhmc: "",
      time: [],
      condition:'',
      options:[
        { label: "文章标题", value: 1 },
          { label: "作者名称", value: 2 },
      ],
      fansSection:1,
      infoType: "", //信息类型
      infoTypeOptions: [], //信息类型
    };
  },
  created() {
    this.getErrorTypeList()
  },
  methods: {
     // 获取错误类型
     async getErrorTypeList() {
      const res = await Error.getMisrepresentationSelect();
      if (res && res.data && res.data.code == 200) {
        this.infoTypeOptions = res.data.data;
      }
    },
    getData() {
     
      const { listType, $refs,fansSection,condition } = this;
      const woleile = () => {
        const obj = {}
        if (fansSection == 1 && condition.length > 0) obj.title = condition;
        if (fansSection == 2 && condition.length > 0) obj.author = condition;
        return obj
    }
      const obj = {
        1: {
          messageTypeList: $refs?.MessageType?.status, // 有害信息类型
          accountName: this.zhmc,
        },
        2:{
        messageTypes: $refs?.MessageType?.status, // 有害信息类型
        ...woleile() 
        },
        3:{
          planId: this.infoType, // 有害信息类型
        ...woleile()
        }
      };
      const timeObj ={}
      if(listType == 3) {
        timeObj.publishTimeStart = this.time?this.time[0]:''
        timeObj.publishTimeEnd = this.time?this.time[1]:''
      }else {
        timeObj.timeStart = this.time?this.time[0]:''
        timeObj.timeEnd = this.time?this.time[1]:''
      }
      console.log(this.time)
      console.log(timeObj)
      return { ...obj[listType] ,...timeObj};
    },
    resetCom() {
      const { listType, $refs } = this;
      const obj = {
        1: () => {
          ($refs.MessageType.status = []), // 有害信息类型
            (this.zhmc = "");
        },
        2:()=> {
            ($refs.MessageType.status = [])// 有害信息类型
            this.condition = ''
            this.fansSection = 1
        },
        3:()=> {
          this.infoType = '',
            this.condition = ''
            this.fansSection = 1
        }
      };
      obj[listType]();
      this.time = "";
      
    },
  },
};
</script>
